import React from 'react'
import config from '../../../config'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='content has-text-centered'>
          <div class='links'>
            <a href='https://github.com/magento/magento2' rel='noopener' target='_blank'>
              GitHub英文版
            </a>
            <a href='https://gitee.com/zouhongzhao/magento2' rel='noopener' target='_blank'>
              Gitee国内镜像
            </a>
            <a href='https://bbs.mallol.cn/' rel='noopener' target='_blank'>
              开发者社区
            </a>
            <a href='https://www.kancloud.cn/zouhongzhao/magento2-in-action' rel='noopener' target='_blank'>
              Magento2实战
            </a>
            <a href='https://devdocs.magento.com/guides/v2.4/' rel='noopener' target='_blank'>
              开发文档
            </a>
            <a href='https://www.meetup.com/pro/magento/' rel='noopener' target='_blank'>
              Magento 聚会
            </a>
            <a href='https://opensource.magento.com/' rel='noopener' target='_blank'>
              Magento 开源项目
            </a>
          </div>
          <div class='links'>
            <span>友情链接</span>
           
          </div>
          <p>
            {config.copyright}
            <a rel="nofollow" target="__blank" href="https://beian.miit.gov.cn/#/Integrated/index">鄂ICP备13000850号-2</a>
          </p>
          <p>Powered by <a href='https://www.gatsbyjs.org'>Gatsby</a></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
