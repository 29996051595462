module.exports = {
  siteTitle: 'Magento2中文站', // Site title.
  siteTitleAlt: '世界排名第一的开源电商系统', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.mallol.cn', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'MALLOL是国内最专业的Magento2开发者社区，Magento是全球最受欢迎的商业平台,Magento2是一款现代的,高性能的,可扩展的电子商务平台,全球超过33万的大商家在使用', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'mallol', // Disqus shortname.
  userName: '老邹',
  userTwitter: 'vaibhaved',
  userLocation: 'wuhan, hubei',
  userDescription: '',
  copyright: 'Copyright © Magento2中文站 2018-2023. All Rights Reserved. ', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
